@import 'mixins/main-grid';

#benchmark-app {
    padding-top: 30px;
    padding-bottom: 80px;
    // background-color: $light-gray-25;
    .--dark-gray {
        color: $dark-gray;
    }
    .relative {
        position: relative;
    }
    .benchmark {
        $bm: &;
        &-preparing {
            position: fixed;
            inset: 0;
            display: grid;
            place-items: center;
            background-color: $white;
            z-index: 1000;
        }
        &-control-btn {
            display: flex;
            align-items: center;
            border-radius: 8px;
            border: 1px solid $light-gray;
            background: $white;
            padding: 8px 16px;
            gap: 8px;
            @include font-size(14, 14, 400);
            &:hover {
                background-color: $light-gray;
            }

            &.--pdf-btn {
                background-color: $green;
                color: $white;
                &:hover {
                    opacity: 0.9;
                }
            }
        }
        &-title {
            @include font-size(32, 32, 700, 0.64);
            margin-bottom: 28px;
        }

        &-grid {
            align-items: start;
            row-gap: 80px !important;
            @include main-grid();
            @include device-lg('min') {
                padding-right: 40px !important;
                padding-left: 40px !important;
            }
            &__chart {
                grid-column: 1 / 16;
                &__controls {
                    padding-top: 60px;
                    display: flex;
                    gap: 6px;
                    justify-content: center;
                }
            }
            &__filters {
                grid-column: 17 / -1;
                padding-left: 40px;
                border-left: $light-gray 1px solid;
                &__title {
                    color: $green;
                    @include font-size(20, 20, 600);
                    margin-bottom: 20px;
                }
                &__chips {
                    margin-top: 28px;
                    display: flex;
                    flex-direction: column;
                    gap: 28px;
                }
            }
            &__card-list {
                display: flex;
                flex-direction: column;
                margin-top: 40px;
                gap: 14px;
                border-top: $light-gray 1px solid;
                padding-top: 20px;
                grid-column: 14 / -1;

                &__title {
                    padding-bottom: 6px;
                    color: $green;
                    @include font-size(20, 20, 600);
                }
            }
        }

        &-range-box {
            display: flex;
            gap: 32px;
            padding-bottom: 20px;
        }

        &-range {
            max-width: 180px;
            appearance: none;
            width: 100%;
            cursor: pointer;
            outline: none;
            border-radius: 8px;
            height: 3px;
            background: $medium-gray;

            &.axe-y {
                position: absolute;
                left: -74px;
                top: 10px;
                transform: rotate(-90deg) translateX(-50%);
            }
            &.axe-x {
                position: absolute;
                right: 20px;
                bottom: 14px;
            }

            &::-webkit-slider-thumb {
                -webkit-appearance: none;
                appearance: none;
                height: 12px;
                width: 12px;
                background-color: $black;
                border-radius: 50%;
                border: none;
                transition: 0.2s ease-in-out;
            }

            &::-moz-range-thumb {
                height: 12px;
                width: 12px;
                background-color: $black;
                border-radius: 50%;
                border: none;
                transition: 0.2s ease-in-out;
            }

            &::-webkit-slider-thumb:hover {
                box-shadow: 0 0 0 3px rgba(255, 85, 0, 0.1);
            }

            &:active::-webkit-slider-thumb {
                box-shadow: 0 0 0 6px rgba(255, 85, 0, 0.2);
            }

            &:focus::-webkit-slider-thumb {
                box-shadow: 0 0 0 6px rgba(255, 85, 0, 0.2);
            }

            &::-moz-range-thumb:hover {
                box-shadow: 0 0 0 3px rgba(255, 85, 0, 0.1);
            }

            &:active::-moz-range-thumb {
                box-shadow: 0 0 0 6px rgba(255, 85, 0, 0.2);
            }

            &:focus::-moz-range-thumb {
                box-shadow: 0 0 0 6px rgba(255, 85, 0, 0.2);
            }
        }

        &-chips-group {
            display: flex;
            column-gap: 6px;
            row-gap: 12px;
            flex-wrap: wrap;
            &.--is-multiple {
                .benchmark-chips.--selected {
                    display: flex;
                    gap: 8px;
                    &::after {
                        content: url("data:image/svg+xml,%3Csvg width='14' height='14' viewBox='0 0 14 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.5 3.5L10.5 10.5M10.5 3.5L3.5 10.5' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
                    }
                }
            }
        }

        &-chips-label {
            @include font-size(14, 14, 700);
            padding-bottom: 12px;
            display: inline-block;
        }

        &-chips {
            &:hover {
                background-color: $light-gray;
            }
            color: $dark-gray;
            border-radius: 20px;
            border: 1px solid $medium-gray;
            height: 36px;
            padding: 10px 14px;
            text-align: center;
            vertical-align: middle;
            @include font-size(14, 14, 700);
            &.--selected {
                color: $white;
                border: 1px solid $black;
                background: $black;
                &:hover {
                    opacity: 0.95;
                }
            }
        }
        &-company {
            &__note-btn {
                color: $green;
                text-align: right;
                @include font-size(14, 14, 700);
                display: flex;
                gap: 4px;
                align-items: center;
            }
        }
        &-company-card {
            // margin-top: 10px;
            border-radius: 16px;
            border: 1px solid $medium-gray;
            background: $white;
            padding: 16px;
            display: flex;
            flex-direction: column;
            gap: 18px;
            &__header {
                display: flex;
                justify-content: space-between;
                gap: 16px;
                &__name-position {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    flex-shrink: 0;
                }
                &__name {
                    color: #000;
                    @include font-size(20, 20, 700, 0.4);
                }
                &__geo {
                    text-align: end;
                }
            }
            &__info {
                display: flex;
                gap: 8px;
                flex-wrap: wrap;
                &__item {
                    border-radius: 32px;
                    background: $light-gray;
                    padding: 6px 14px;
                    @include font-size(12, 12, 700);
                    color: $dark-gray;
                }
            }
            &__footer {
                display: flex;
                justify-content: space-between;
                align-items: center;
                &__info {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    &__btn {
                        color: $green;
                        text-align: right;
                        @include font-size(14, 14, 700);
                    }
                }
                &__type {
                    color: $black;
                    @include font-size(18, 18, 600);
                }
                &__controls {
                    display: flex;
                    gap: 6px;
                    button {
                        font-weight: 700;
                    }
                }
            }
        }

        &-notes-modal {
            position: fixed;
            top: 0;
            z-index: 10;
            background-color: rgba(0, 0, 0, 0.8);
            height: 100%;
            width: 100%;
            border: none;
            overflow: auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            &__box {
                background-color: $white;
                border: 1px solid $medium-gray;
                border-radius: 10px;
                max-width: 600px;
                width: 100%;
                padding: 30px;
                position: relative;
                margin: auto;

                &__content {
                    // display: flex;
                    // justify-content: space-between;
                    // gap: 8px;
                    // align-items: flex-start;

                    .--textarea,
                    textarea {
                        width: 100%;
                        border-color: $medium-gray;
                    }

                    textarea {
                        height: 250px;
                        &:focus {
                            border-color: $green;
                        }
                    }
                }
            }

            &__header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 12px;
            }

            &__info {
                flex-basis: 50%;
                padding: 16px;
                background-color: $white;
                border: 1px solid $medium-gray;
                border-radius: 8px;
                min-height: 200px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                gap: 16px;

                & > div {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;
                }
            }

            &__footer {
                display: flex;
                gap: 8px;
                justify-content: flex-end;
                margin-top: 20px;
            }
        }
        &-historical {
            &-header {
                display: flex;
                align-items: center;
                gap: 24px;
                &__title {
                    @include font-size(24, 24, 700);
                    color: black;
                    margin-top: 12px;
                }
                &__subtitle {
                    color: $dark-gray;
                    @include font-size(20, 20, 700);
                }
            }
            &-cards {
                padding-top: 32px;
                // padding-left: 48px;
                display: flex;
                flex-direction: column;
                gap: 14px;
            }
        }
        &-header {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            .benchmark-control-btn {
                color: $green;
                font-weight: 700;
            }
            &__controls {
                display: flex;
                align-items: center;
                gap: 16px;
            }
        }

        &-mobile {
            &-hero {
                background-color: $green;
                padding: 24px 0;
                &__title {
                    color: $white;
                }
            }
            &-selects {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                gap: 8px;
                padding-top: 16px;
                .benchmark-search-company {
                    @include device-lg('min') {
                        flex-shrink: 0;
                    }
                }
            }
            &-controls {
                display: flex;
                flex-wrap: nowrap;
                justify-content: space-between;
                gap: 8px;
                padding-top: 32px;
                button {
                    width: 100%;
                }
            }
            &-company-cards {
                background-color: $light-gray-25;
                padding-bottom: 36px;
                .benchmark-grid__card-list {
                    border: none;
                    margin-top: 20px;
                }
            }
        }

        &-chart-container {
            width: 100%;
            canvas {
                width: 100% !important;
                height: 100% !important;
            }

            @include device-lg {
                position: absolute;
                inset: 0;
                width: 800px;
                transform: translateX(-200%);
                z-index: -1;
                pointer-events: none;
                canvas {
                    position: absolute;
                    width: 800px !important;
                    height: 800px !important;
                }
            }

            &.--downloading {
                position: absolute;
                inset: 0;
                width: 800px;

                canvas {
                    position: absolute;
                    width: 800px !important;
                    height: 800px !important;
                }
            }
        }
    }
}

#benchmark-app-admin {
    .questions-box {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        @include device-lg {
            grid-template-columns: 1fr;
        }
    }
    .benchmark-admin-table {
        /* Estilo para la barra de desplazamiento */
        &::-webkit-scrollbar {
            width: 10px; /* Ancho de la barra de desplazamiento */
            height: 10px;
        }

        /* Estilo para el riel de la barra de desplazamiento */
        &::-webkit-scrollbar-track {
            background-color: $light-gray-25; /* Color de fondo del riel */
            border: 0; /* Borde del riel */
        }

        /* Estilo para el pulgar de la barra de desplazamiento */
        &::-webkit-scrollbar-thumb {
            background-color: $green; /* Color del pulgar (barra móvil) */
            border: 0; /* Borde del pulgar */
        }
        overflow: auto;
        width: 100%;
        max-height: 80vh; // Fallback
        max-height: 80lvh;
        table {
            width: 100%;
            overflow: auto;
            border-spacing: 0; /* Elimina el espacio entre las celdas */
            border-collapse: unset;
        }

        th,
        td {
            border: 1px solid #ddd;
            padding: 6px;
            white-space: nowrap;
            box-sizing: border-box; /* Incluye el ancho del borde en el tamaño total de la celda */
        }

        th {
            position: sticky;
            top: 0;
            background-color: var(--w-color-grey-50); // Wagtail
            z-index: 3; /* Asegura que las celdas de encabezado estén encima de las celdas regulares */
        }

        th:first-child {
            left: 0;
            z-index: 4; /* Asegura que la esquina superior izquierda esté encima de las celdas regulares y del encabezado */
        }

        td:first-child {
            position: sticky;
            left: 0;
            background-color: var(--w-color-grey-50); // Wagtail
            z-index: 2;
        }

        th.company-name {
            width: 170px;
            min-width: 170px;
            max-width: 170px;
            white-space: wrap;
            padding: 12px;
            border-bottom-color: $green;
        }

        td.question-name {
            width: 354px;
            min-width: 354px;
            max-width: 354px;
            white-space: wrap;
            border-right-color: $green;
            .tooltip {
                position: fixed;
                font-size: 12px;
                line-height: 20px;
                background: $green;
                border-radius: 6px;
                visibility: hidden;
                padding: 6px;
                opacity: 0;
                transition:
                    opacity 0.2s,
                    visiblity 0s;
                color: $white;
                z-index: 50;
                font-weight: 700;
            }
            &:hover .tooltip {
                visibility: visible;
                opacity: 1;
            }
        }

        td.select-field {
            padding: 4px;
            select {
                border: 0;
            }
        }

        th.period-name {
            z-index: 10;
            background-color: $white;
            color: $green;
        }

        td.note-field {
            background-color: $white;
            font-weight: 700;
            border-right-color: $green;
            width: 170px;
            min-width: 170px;
            max-width: 170px;
            white-space: wrap;
            padding: 8px;
        }

        select {
            width: 100%;
            font-size: 14px;
        }

        button.note-btn {
            margin: auto;
            display: block;
        }
    }
    .note-dialog-footer {
        display: flex;
        justify-content: flex-end;
    }
    .table-step-controls {
        display: flex;
        justify-content: space-between;
        gap: 8px;
        min-width: 100%;
        max-width: 100%;
        align-items: center;
        &__right {
            display: flex;
            gap: 28px;
            align-items: center;
        }
    }

    .benchmark-admin-companies-step {
        @include device-lg('min') {
            columns: 2;
        }
        @include device-xl('min') {
            columns: 3;
        }
    }

    .button-submit {
        svg {
            transition: all 0.3s ease;
            display: none;
        }
    }

    .loading {
        pointer-events: none;
        align-items: center;
        display: inline-flex;
        svg {
            display: inline-block;
            height: 1.5em;
            opacity: 0.8;
            padding: 0;
            vertical-align: text-top;
            width: 1.5em;
        }
    }
    .feedback-error {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
    }
}
