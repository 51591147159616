.hero-equipment {
    background: linear-gradient(90deg, #008657 0%, #005437 100%);
    color: $white;
    padding-top: 50px;
    padding-bottom: 50px;

    &__container {
        display: grid;
        gap: 1rem;
        
        grid-template-areas: 
        "title"
        "image";
        @include device-md("min"){
            align-items: center;
            grid-template-columns: 1fr 1fr;
            grid-template-areas: 
            "title image"
        }

        &.--has-selects {
            grid-template-areas: 
            "title "
            "select"
            "image";
            @include device-md("min"){
                grid-template-columns: 1fr 1fr;
                grid-template-areas: 
                "title image"
                "select image";
            }
        }

        &.--has-products {
            grid-template-areas: 
            "title"
            "image"
            "product";
            @include device-md("min"){
                grid-template-columns: 1fr 1fr;
                grid-template-areas:
                ". image"
                "title image"
                "product image"
                ". image"
            }
        }

        &.--has-selects.--has-products {
            grid-template-areas: 
            "title"
            "select"
            "image"
            "product";
            @include device-md("min"){
                grid-template-columns: 1fr 1fr;
                grid-template-areas: 
                "title image"
                "product image"
                "select image";
            }
        }
    }
    
    &__title.h1 {
        grid-area: title;
        line-height: 80px;
        @include device-md {
            @include font-size(40, 50, 700, -2);
        }
    }

    &__select {
        grid-area: select;
        margin-top: 2.5rem;

        @include device-md {
            margin-top: 0;
        }

        .--text-s {
            font-weight: 500;
        }

        &__content {
            display: flex;
            gap: 20px;
            label {
                margin-bottom: 8px;
                display: inline-block;
            }
            @include device-lg {
                flex-direction: column;
            }
        }
    }


    &__image {
        width: 100%;
        max-width: 540px;
        height: 100%;
        max-height: 359px;
        object-fit: contain;
        grid-area: image;

        @include device-sm {
            max-width: 100%;
        }
    }

    &__text-and-button {
        grid-area: product;
        display: flex;
        align-items: center;
        gap: 12px;

        .--text-xs {
            font-weight: 500;
        }

        @include device-lg {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    @include device-md {
        background: linear-gradient(
            180deg,
            #008657 0%,
            #006c46 99.99%,
            #005437 100%
        );


        &__title {
            margin-bottom: 24px;

            div {
                display: flex;
                flex-direction: column;
                max-width: 257px;

                .--select {
                    margin-bottom: 20px;
                }
            }
        }

        &__text-and-button {
            flex-direction: row;
            max-width: 375px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            p,
            .--text-xs {
                max-width: 160px;
                margin-bottom: 0;
            }

            .btn {
                height: min-content;
            }
        }
    }
}
